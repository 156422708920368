import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import Article from '../components/article'
import VeganEnergyChart from '../components/vegan-energy-chart'

export const query = graphql`
  query VeganEnergy($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      partnerPromotion
      sections {
        ... on Node {
          ... on ContentfulArticle {
            __typename
            title
            body {
              raw
              references {
                ...ArticleReferences
              }
            }
            aside {
              raw
              references {
                ...ArticleReferences
              }
            }
          }
          ... on ContentfulTable {
            __typename
            title
            data {
              tableData
            }
            images {
              title
              assets {
                title
                description
                file {
                  url
                  fileName
                }
                gatsbyImageData(
                  quality: 60
                  layout: FULL_WIDTH
                  formats: [WEBP]
                )
              }
            }
            footer
          }
        }
      }
    }
  }
`

const VeganEnergy: React.FC<PageProps<GatsbyTypes.VeganEnergyQuery>> = ({
  data: { page = {} },
}) => {
  const article = page.sections?.find(
    section => section?.__typename === 'ContentfulArticle'
  ) as GatsbyTypes.ContentfulArticle | undefined

  const table = page.sections?.find(
    section => section?.__typename === 'ContentfulTable'
  ) as GatsbyTypes.ContentfulTable | undefined

  return (
    <Layout page={page} subnav={true} masthead="default">
      <Section as="div" variant={page.sections ? 'header' : 'page'}>
        <PageHeader
          topic={page.topic?.heading || page.topic?.name}
          heading={page.heading || page.title}
          subheading={page.subheading}
          standfirst={page.standfirst?.standfirst}
        />
      </Section>
      <Section pos={0} count={2} variant="page">
        <Article body={article?.body} aside={article?.aside} />
      </Section>
      <Section pos={1} count={2} fill="sprayNESW" variant="page">
        <VeganEnergyChart
          title={table.title}
          tableData={table.data.tableData}
          logos={table.images.assets}
          footer={table.footer}
        />
      </Section>
    </Layout>
  )
}

export default VeganEnergy
