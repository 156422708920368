import React from 'react'
import { ReactNode } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  data?: ReactNode[][]
  variant?: 'fill'
}

const Table: React.FC<Props> = ({ data = [], variant, ...other }) => {
  const isFilled = variant === 'fill'

  const table: ThemeUIStyleObject = {
    borderCollapse: isFilled ? 'separate' : 'collapse',
    borderSpacing: 2,
    marginY: 10,
  }

  const thead: ThemeUIStyleObject = {
    borderBottom: isFilled ? 'none' : '2px solid',
    borderColor: 'primary',
  }

  const th: ThemeUIStyleObject = {
    minWidth: '6rem',
    borderBottom: '1px solid',
    borderColor: 'muted',
    paddingY: isFilled ? 11 : 8,
    paddingX: isFilled ? 12 : 9,
    textAlign: isFilled ? 'center' : 'left',
    fontFamily: 'heading',
    fontSize: isFilled ? 3 : 0,
    verticalAlign: 'bottom',
  }

  const td: ThemeUIStyleObject = {
    ...th,
    fontSize: isFilled ? 1 : 0,
    fontFamily: 'body',
    fontWeight: isFilled ? 'light' : 'body',
    lineHeight: 'body',
    backgroundColor: isFilled ? 'background' : 'transparent',
    verticalAlign: isFilled ? 'middle' : 'top',
  }

  const tabulate = (rows: ReactNode[][], header?: boolean): ReactNode =>
    rows.map((row, i) => (
      <tr key={i}>
        {row.map((col, i) =>
          header ? (
            <th key={i} sx={th}>
              {col}
            </th>
          ) : (
            <td key={i} sx={td}>
              {col}
            </td>
          )
        )}
      </tr>
    ))

  const head = tabulate(data.slice(0, 1), true)
  const body = tabulate(data.slice(1))

  return (
    <Box sx={{ overflow: 'auto' }}>
      <table sx={table} {...other}>
        <thead sx={thead}>{head}</thead>
        <tbody>{body}</tbody>
      </table>
    </Box>
  )
}

export default Table
