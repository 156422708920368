/* eslint-disable react/jsx-key */
import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import Table from './table'

export interface Logo {
  title: string
  description: string
  file: {
    url: string
    fileName: string
  }
  gatsbyImageData: {
    quality: number
    layout: string
    formats: string
  }
}

interface Props {
  title: string
  tableData: Array<string[]>
  logos: Array<Logo>
  footer: string[]
}

const VeganEnergyChart: React.FC<Props> = ({
  title,
  tableData,
  logos,
  footer,
}) => {
  const reformatTableData = (tableData: Array<string[]>) => {
    const tableHeaders: string[] = tableData[0].slice(0, 3)
    const tableBody = tableData.slice(1).map(element => {
      return {
        name: element[0],
        waste: element[1],
        details: element[2],
        logo: element[3],
      }
    })
    return { tableHeaders, tableBody }
  }

  const wrapper: ThemeUIStyleObject = {
    paddingY: [14, 15, 16],
    paddingX: [11, 13, 16],
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: 12,
    textAlign: 'center',
  }

  const table: ThemeUIStyleObject = {
    marginX: 'auto',
  }

  const { tableHeaders, tableBody } = reformatTableData(tableData)

  return (
    <Box sx={wrapper}>
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      {tableHeaders && tableBody && (
        <Table
          sx={table}
          data={[
            tableHeaders,
            ...tableBody.map(supplier => {
              const logo = logos.find(logo => logo.title === supplier.logo)
              const image = logo?.gatsbyImageData as unknown as IGatsbyImageData
              const description = logo?.description
              return [
                <GatsbyImage
                  image={image}
                  backgroundColor="ffffff"
                  alt={description ?? 'Energy supplier logo'}
                />,
                <Text variant="lrg">{supplier.waste}</Text>,
                `${supplier.details}`,
              ]
            }),
          ]}
          variant="fill"
        />
      )}
      {footer &&
        footer.map(text => {
          return (
            <Text sx={{ mt: 10, textAlign: 'center' }} variant="intro">
              {text}
            </Text>
          )
        })}
    </Box>
  )
}

export default VeganEnergyChart
